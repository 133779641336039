<template>
  <SettingsCard :title="$t('PageAccount.NotificationsSectionText').value">
    <template #subheader>
      <XeAppDescriptionList borderTop>
        <AppDescriptionListItem>
          <template #title>
            <p class="subheader">{{ $t('PageAccount.NotificationsSectionSubHeaderText').value }}</p>
            <!--Get the latest market news and exciting offers from Xe. You can turn these off at any
              time.-->
          </template>
        </AppDescriptionListItem>
      </XeAppDescriptionList>
    </template>
    <XeAppDescriptionList>
      <AppDescriptionListItem>
        <template #title>
          <p class="email-title">
            {{ $t('PageAccount.NotificationsSectionMarketNewsOffers').value }}
          </p>
          <!-- Email -->
        </template>
        <template #content>
          <AppInputToggle v-model="emailMarketingToggle" />
        </template>
      </AppDescriptionListItem>
    </XeAppDescriptionList>
  </SettingsCard>
</template>

<script>
import { defineComponent, ref, watch, computed } from '@vue/composition-api'

import { storeToRefs } from 'pinia'

import SettingsCard from '@galileo/components/Views/Account/SettingsCard/SettingsCard'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'

import { AppDescriptionListItem, AppInputToggle } from '@oen.web.vue2/ui'

import { useProfileStore, useI18nStore, useAuthStore } from '@galileo/stores'

export default defineComponent({
  components: {
    SettingsCard,
    AppDescriptionListItem,
    XeAppDescriptionList,
    AppInputToggle,
  },
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const profileStore = useProfileStore()

    const { isCorporateAccount } = storeToRefs(authStore)
    const { promptForMarketingConsent } = storeToRefs(profileStore)
    const emailMarketingToggle = ref(props.user.willReceiveEmailMarketing)
    const corpConsent = computed(() => promptForMarketingConsent)

    watch(corpConsent, (value) => {
      if (isCorporateAccount.value && value) {
        emailMarketingToggle.value = value?.consentedToMarketing
      }
    })

    const loading = ref(false)

    watch(emailMarketingToggle, async (value) => {
      loading.value = true
      const consent = {
        promptForMarketingConsent: false,
        email: value,
        push: false,
      }
      try {
        await profileStore.updateMarketingConsent(consent)
      } finally {
        loading.value = false
      }
    })

    return {
      emailMarketingToggle,
      $t,
    }
  },
})
</script>

<style scoped>
p.subheader {
  @apply text-sm font-normal leading-5 text-tertiary-text;
}
p.email-title {
  @apply font-medium text-base text-primary-text;
}

::v-deep .toggle-button {
  &.on {
    background-color: var(--theme-color-primary, theme('colors.blue.default'));

    .knob .halo {
      background-color: rgba(0, 17, 51, 0.05);
    }
  }
}
</style>

<template>
  <AppModal v-model="model" :title="$t('EditPersonalInfoModal.ModalTitleLabel').value">
    <template #header="{ dismiss }">
      <AppCardHeader>
        <h1>{{ $t('EditPersonalInfoModal.ModalTitleLabel').value }}</h1>
        <template #right>
          <XeBackButton analytics-name="add-recipient-type-close" icon="x" @click="dismiss" />
        </template>
      </AppCardHeader>
    </template>

    <AppInputText
      :label="$t('PageAccount.PersonalInfoSectionFirstNameLabel').value"
      v-model="form.firstName.$value"
      :validation="form.firstName"
    />
    <AppInputText
      :label="$t('PageAccount.PersonalInfoSectionLastNameLabel').value"
      v-model="form.lastName.$value"
      :validation="form.lastName"
    />

    <div class="row">
      <AppInputText
        class="mr-2 w-1/3"
        :label="$t('PageAccount.PersonalInfoSectionPrefixLabel').value"
        v-model="form.mobilePrefix.$value"
        :validation="form.mobilePrefix"
      />

      <AppInputText
        :label="$t('PageAccount.PersonalInfoSectionPhoneLabel').value"
        v-model="form.phone.$value"
        :validation="form.phone"
      />
    </div>

    <AppInputCountry
      v-model="userTemp.country"
      :empty-filter-message="$t('AddressSearch.noResult').value"
      analytics-name="send-money-amount-country"
      :label="$t('PageAccount.AccountSectionCountryLabel').value"
      :countries="countries"
      :placeholder="$t('EditAccountModal.SelectCountryPlaceholder').value"
    />
    <div class="mb-1" v-if="manuallyEnterAddress">
      <div class="row">
        <AppInputText
          class="mr-2"
          :label="$t('PageAccount.PersonalInfoSectionStreetNameLabel').value"
          v-model="userTemp.streetName"
        />
        <AppInputText
          class="w-1/5"
          :label="$t('PageAccount.PersonalInfoSectionAddressNumberLabel').value"
          v-model="userTemp.streetNumber"
        />
      </div>
    </div>
    <XeAddressSearch
      v-else
      :key="userTemp.address"
      :dropdownLabel="$t('PageAccount.PersonalInfoSectionAddressLabel').value"
      :search-promise-lazy="searchPromiseLazy"
      :get-address-details-promise-lazy="getAddressDetailsPromiseLazy"
      @select="addressSelected"
      @manual="onEnterManuallyClick('Dropdown')"
      :display-value="userTemp.address"
      :show-manual-selection="false"
    />

    <AppInputText
      :label="$t('PageAccount.PersonalInfoSectionCityLabel').value"
      v-model="userTemp.place"
    />

    <div class="row">
      <AppInputDropdown
        selectedValueField="name"
        selectedDisplayField="name"
        class="mr-2 w-3/5"
        :label="$t('PageAccount.PersonalInfoSectionStateLabel').value"
        :options="states"
        v-model="userTemp.state"
      />
      <AppInputText
        class="ml-2"
        :label="$t('PageAccount.PersonalInfoSectionZipCodeLabel').value"
        v-model="userTemp.postalCode"
      />
    </div>

    <AppButton
      :disabled="form.$anyInvalid"
      analytics-name="activity-send-money"
      @click="updateUser"
      class="send-money-button"
      theme="primary"
      :loading="isSaving"
    >
      {{ $t('EditPersonalInfoModal.ButtonUpdateText').value }}
    </AppButton>
  </AppModal>
</template>

<script>
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch'
import XeInputDropdown from '@galileo/components/XeInputDropdown/XeInputDropdown'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import UserPersonalInformation from '@galileo/models/UserProfile/UserPersonalInformation'

import { ref, watch, reactive } from '@vue/composition-api'
import { useValidation, usePromiseLazy } from 'vue-composable'
import { required } from '@vuelidate/validators'

import {
  useVModel,
  AppModal,
  AppInputText,
  AppButton,
  AppCardHeader,
  AppInputDropdown,
  AppInputCountry,
  AppInputPassword,
  useAlert,
} from '@oen.web.vue2/ui'

import { useI18nStore, useResourcesStore, useCountriesStore } from '@galileo/stores'

export default {
  title: 'EditPersonalInfoModal',
  components: {
    AppModal,
    AppInputText,
    AppButton,
    AppCardHeader,
    AppInputDropdown,
    XeBackButton,
    AppInputCountry,
    AppInputPassword,
    XeAddressSearch,
    XeInputDropdown,
  },
  props: {
    value: {
      type: Boolean,
      //required: true,
    },
    user: {
      type: Object,
      require: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const isSaving = ref(false)
    const states = ref([])
    let userInfo = new UserPersonalInformation(props.user)
    let userTemp = reactive(userInfo)
    const countriesStore = useCountriesStore()
    const countries = countriesStore.getCountries

    const resourcesStore = useResourcesStore()

    watch(
      () => userTemp['country'],
      (country, prev) => {
        countriesStore.getStatesByCountryCode(country).then((result) => {
          states.value = result
        })
        if (country != prev && prev != '') {
          //empty address details
          userTemp.resetAddress()
        }
      }
    )
    //trigger watch
    userTemp['country'] = props.user.country

    const form = useValidation({
      firstName: {
        $value: ref(userTemp.firstName),
        required: {
          $validator: required.$validator,
          $message: $t('EditPersonalInfoModal.FirstNameRequiredError').value,
        },
      },
      lastName: {
        $value: ref(userTemp.lastName),
        required: {
          $validator: required.$validator,
          $message: $t('EditPersonalInfoModal.LastNameRequiredError').value,
        },
      },
      phone: {
        $value: ref(userTemp.phoneNumberNoAreaCode),
        required: {
          $validator: required.$validator,
          $message: $t('EditPersonalInfoModal.PhoneRequiredError').value,
        },
      },
      mobilePrefix: {
        $value: ref(userTemp.mobilePrefix),
        required: {
          $validator: required.$validator,
          $message: $t('EditPersonalInfoModal.PhonePrefixRequiredError').value,
        },
      },
    })

    function updateUser() {
      isSaving.value = true
      userTemp.mapUser(form)
      profileStore.updateUserInfo(userTemp).then((success) => {
        if (success) {
          props.onSave()
          model.value = false
        } else {
          const { add } = useAlert()
          add(`${$t('EditPersonalInfoModal.ErrorOccuredOnSaving').value}`)
        }

        isSaving.value = false
      })
    }

    //-------------- XE address search

    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: userTemp.country,
        })
        return result
      })
    )

    //TODO move into composable?
    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => {
        let result = resourcesStore.getAddressDetails(selectedId)
        return result
      })
    )

    /**
     * parse the address and set it to the user
     */
    function addressSelected(address) {
      if (address) {
        userTemp.setAddress(address)
      }
    }

    const manuallyEnterAddress = ref(false)

    function onEnterManuallyClick(arg) {
      manuallyEnterAddress.value = !manuallyEnterAddress.value
    }

    return {
      manuallyEnterAddress,
      onEnterManuallyClick,
      $t,
      form,
      states,
      userTemp,
      countries,
      updateUser,
      addressSelected,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      model,
      isSaving,
    }
  },
}
</script>

<style scoped>
.row {
  @apply flex justify-between;
}
</style>

<template>
  <SettingsCard :title="$t('PageAccount.LanguageSectionText').value" class="language-card">
    <XeAppDescriptionList borderTop>
      <AppDescriptionListItem class="language-card-desc-list">
        <template #content>
          <AppInputDropdown
            v-model="selectedLanguage"
            :label="$t('PageAccount.LanguageSectionSubHeaderText').value"
            :options="languagesName"
            selected-display-field="value"
            selected-value-field="key"
          >
            <template #option="{ option }">
              <div class="option">
                <p>{{ option.value }}</p>
                <AppIcon name=" " v-if="option.key === selectedLanguage">
                  <IconCheckmark />
                </AppIcon>
              </div>
            </template>
          </AppInputDropdown>

          <AppButton
            type="button"
            theme="secondary"
            :loading="isLoading"
            @click="selectPreferredLanguage"
          >
            {{ $t('PageAccount.UpdateLanguage').value }}
          </AppButton>
        </template>
      </AppDescriptionListItem>
    </XeAppDescriptionList>
  </SettingsCard>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import SettingsCard from '@galileo/components/Views/Account/SettingsCard/SettingsCard'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'
import { AppDescriptionListItem, AppInputDropdown, AppButton, AppIcon } from '@oen.web.vue2/ui'
import { IconCheckmark } from '@oen.web.vue2/icons'

import {
  useI18nStore,
  useResourcesStore,
  useAnalyticsStore,
  useProfileStore,
  useDeviceStore,
  useAuthStore,
} from '@galileo/stores'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default defineComponent({
  components: {
    SettingsCard,
    AppDescriptionListItem,
    XeAppDescriptionList,
    AppInputDropdown,
    AppButton,
    AppIcon,
    IconCheckmark,
  },
  setup() {
    const resourcesStore = useResourcesStore()
    const i18nStore = useI18nStore()
    const { $t, testingLanguages, availableLanguages } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const profileStore = useProfileStore()
    const deviceStore = useDeviceStore()
    const isLoading = ref(false)

    const authStore = useAuthStore()

    const consolidatedLanguages = [
      { value: $t('AccountDetailsLanguageListItem.English').value, key: 'en' },
      { value: $t('AccountDetailsLanguageListItem.Spanish').value, key: 'es' },
      { value: $t('AccountDetailsLanguageListItem.Dutch').value, key: 'nl' },
      { value: $t('AccountDetailsLanguageListItem.French').value, key: 'fr' },
      { value: $t('AccountDetailsLanguageListItem.German').value, key: 'de' },
      { value: $t('AccountDetailsLanguageListItem.Norwegian').value, key: 'nb' },
      { value: $t('AccountDetailsLanguageListItem.Swedish').value, key: 'sv' },
      { value: $t('AccountDetailsLanguageListItem.Danish').value, key: 'da' },
      { value: $t('AccountDetailsLanguageListItem.Italian').value, key: 'it' },
      { value: $t('AccountDetailsLanguageListItem.Portuguese').value, key: 'pt' },
    ]

    const languagesName = computed(() => {
      let languageList = consolidatedLanguages
      if (availableLanguages?.length) {
        languageList = languageList.filter((language) => availableLanguages.includes(language.key))
      }

      if (testingLanguages && !i18nStore.useNewLanguages) {
        languageList = languageList.filter((language) => !testingLanguages.includes(language.key))
      }

      return languageList
    })

    const selectedLanguage = ref(authStore.user.language)

    const selectPreferredLanguage = async () => {
      isLoading.value = true
      const languageCountryCode = i18nStore.getClosestMatchingLocale(selectedLanguage.value)

      analyticsStore.track({
        event: SEGMENT_EVENTS.LANGUAGE_SELECTED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
          language: languageCountryCode,
        },
      })

      await profileStore.changePreferredLanguage({
        language: selectedLanguage.value,
      })

      deviceStore.setLocale(selectedLanguage.value)

      // Fetch the user again
      await authStore.getUserAction(authStore.user.customer.id)
      resourcesStore.getSystemFields(true)

      isLoading.value = false
    }

    return {
      $t,
      isLoading,
      languagesName,
      selectedLanguage,
      selectPreferredLanguage,
    }
  },
})
</script>

<style scoped>
p.subheader {
  @apply text-sm font-normal leading-5 text-tertiary-text;
}
p.email-title {
  @apply font-medium text-base text-primary-text;
}
.language-card {
  ::v-deep .description-list-item-term {
    display: none;
  }

  ::v-deep .p-dropdown-items-wrapper {
    max-height: max-content !important;
  }

  ::v-deep .p-dropdown-items {
    .p-dropdown-item {
      @apply text-left;
    }

    .option {
      @apply flex justify-between;
    }
  }

  ::v-deep .card.setting-card {
    @apply overflow-visible;

    header {
      @apply h-8 pt-1 pb-5;
    }
    .card-content {
      @apply overflow-visible;

      .language-card-desc-list {
        @apply flex-row;
        label {
          @apply text-left;
        }
      }

      .description-list {
        @apply pt-6 pb-0;
      }

      .description-list-item-definition {
        @apply w-full flex items-end;

        .input-wrapper {
          @apply w-full;
        }

        .input {
          @apply m-0 flex flex-col flex-auto items-start;
        }

        button {
          @apply ml-6 flex items-center max-h-12;
          background-color: var(--theme-color-primary-lighter, theme('colors.blue.lighter'));
          max-width: 220px;
          border: var(--theme-button-secondary-border, none);
          &:hover {
            background-color: var(
              --theme-button-secondary-background-color-hover,
              theme('colors.blue.tertiaryDarker')
            );
          }
        }
      }

      @media only screen and (max-width: 600px) {
        .description-list {
          @apply px-0 py-3;
        }
        .description-list-item-definition {
          @apply max-w-full flex-col;
          .input-wrapper {
            @apply pt-6;
          }
          .input {
            @apply w-full;
          }
          button {
            @apply max-w-full ml-0 mt-2;
          }
        }
      }
    }
  }
}
</style>

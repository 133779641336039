<template>
  <div>
    <AppModal v-model="model" :title="$t('EditPasswordModal.Title').value">
      <template #header="{ dismiss }">
        <AppModalHeader>
          <h1>{{ $t('EditPasswordModal.Title').value }}</h1>
          <template #right>
            <XeBackButton analytics-name="change-password-close" icon="x" @click="dismiss" />
          </template>
        </AppModalHeader>
      </template>

      <template #default>
        <form id="password-form" @submit.prevent="updatePassword">
          <EditPasswordComponent
            v-model="oldPassword"
            :user-email="userEmail"
            :password="validatePassword"
          />
        </form>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            type="submit"
            form="password-form"
            analytics-name="change-password-update-button"
            :loading="isSaving"
            :disabled="!validPassword"
            @click="updatePassword"
          >
            <span v-show="!isSaving">{{ $t('EditPasswordModal.Title').value }}</span>
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <InformationAppModal :value="showFailed" icon="warningYellow">
      <h1>{{ $t('EditPasswordModal.FailedTitle').value }}</h1>
      <p>{{ $t('EditPasswordModal.FailedText').value }}</p>

      <template #footer>
        <AppButton @click="showFailed = false">
          {{ $t('EditPasswordModal.ButtonFailedGotIt').value }}
        </AppButton>
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import EditPasswordComponent from '@galileo/components/Views/Account/EditSettingsModal/EditPasswordComponent'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import {
  useVModel,
  useAlert,
  AppModal,
  AppModalFooter,
  AppButton,
  AppCardHeader,
  AppModalHeader,
} from '@oen.web.vue2/ui'

import { useI18nStore, useAuthStore, useAppStore } from '@galileo/stores'

export default {
  name: 'EditPasswordModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppCardHeader,
    XeBackButton,
    EditPasswordComponent,
    AppModalHeader,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const isSaving = ref(false)

    const validPassword = ref(false)
    const oldPassword = ref('')
    let changedPassword = null

    const userEmail = authStore.userProfile?.customer?.email

    const validatePassword = (password) => {
      if (password === null) {
        validPassword.value = false
      } else {
        validPassword.value = true
        changedPassword = password
      }
    }

    const showFailed = ref(false)

    const updatePassword = async () => {
      let passwordChangedSuccessfully = false
      isSaving.value = true
      if (changedPassword !== null) {
        let data = {
          newPassword: changedPassword,
          oldPassword: oldPassword.value,
          username: userEmail,
        }

        try {
          passwordChangedSuccessfully = await authStore.changePassword(
            changedPassword,
            oldPassword.value,
            userEmail
          )
        } catch (e) {
          appStore.logException('Exception during updating password', e)
          showFailed.value = true
        }
      }
      isSaving.value = false

      if (passwordChangedSuccessfully) {
        add(`${$t('EditPasswordModal.PasswordUpdated').value}`)
        props.onSave()
        model.value = false
        isSaving.value = false
      }
    }

    return {
      model,
      $t,
      userEmail,
      isSaving,
      oldPassword,
      validPassword,
      validatePassword,
      updatePassword,
      showFailed,
    }
  },
}
</script>

<style scoped>
::v-deep .card-content {
  @apply px-12;
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}
</style>

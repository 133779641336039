<template>
  <XeAppDescriptionList borderTop>
    <AppDescriptionListItem>
      <template #title>{{ $t('PageAccount.PersonalInfoSectionNameLabel').value }}</template>
      <template #content>
        <p v-if="isCorporateAccount">{{ user.customer.accountName }}</p>
        <p v-else>{{ user.customer.firstName }} {{ user.customer.lastName }}</p>
        <AppButton v-if="!isCorporateAccount" theme="icon" @click="editName">
          <AppIcon name=" ">
            <IconEdit />
          </AppIcon>
        </AppButton>
      </template>
    </AppDescriptionListItem>
  </XeAppDescriptionList>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { AppDescriptionListItem, AppButton, AppIcon } from '@oen.web.vue2/ui'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'
import { IconEdit } from '@oen.web.vue2/icons'

import { useAuthStore, useI18nStore } from '@galileo/stores'

export default defineComponent({
  emits: ['editName'],
  components: {
    AppDescriptionListItem,
    XeAppDescriptionList,
    AppButton,
    AppIcon,
    IconEdit,
  },
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()

    const { isCorporateAccount } = storeToRefs(authStore)

    const editName = () => {
      emit('editName')
    }
    return { editName, isCorporateAccount, $t }
  },
})
</script>

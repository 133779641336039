<template>
  <div>
    <AppInputText
      id="username"
      :value="userEmail"
      class="hidden"
      name="username"
      autocomplete="username"
      readonly
    ></AppInputText>

    <AppInputPassword
      v-model="validation.oldPassword.$value"
      :validation="validation.oldPassword"
      :label="$t('EditPasswordComponent.OldPasswordLabel').value"
      autocomplete="current-password"
      @input="onInput"
    />

    <AppInputPassword
      v-model="validation.password.$value"
      :label="$t('EditPasswordComponent.LabelPassword').value"
      autocomplete="new-password"
      class="password"
    />
    <p v-if="isValid && !showHint" class="hint">
      {{ $t('EditPasswordComponent.StrongPasswordText').value }}
    </p>
    <p v-else-if="showHint" class="hint">
      {{ $t('EditPasswordComponent.MustContainAtLeastText').value }}
      <span :class="{ green: validPassword.minChar, red: !validPassword.minChar }">
        {{ $t('EditPasswordComponent.MinimumCharactersText').value }}</span
      >,
      <span :class="{ green: validPassword.capital, red: !validPassword.capital }">
        {{ $t('EditPasswordComponent.CapitalLetterText').value }}</span
      >,
      <span :class="{ green: validPassword.lower, red: !validPassword.lower }">
        {{ $t('EditPasswordComponent.LowerCaseLetterText').value }}</span
      >, {{ $t('EditPasswordComponent.AndText').value }}
      <span :class="{ green: validPassword.symbol, red: !validPassword.symbol }">
        {{ $t('EditPasswordComponent.SymbolNumberText').value }}
      </span>
    </p>
    <p v-else class="hint">
      {{ $t('EditPasswordComponent.MustContainText').value }}
    </p>
  </div>
</template>

<script>
import { AppInputText, AppInputPassword } from '@oen.web.vue2/ui'

import { ref, watch, reactive, computed } from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import { useI18nStore } from '@galileo/stores'
import { useValidation } from 'vue-composable'

export default {
  title: 'EditPasswordComponent',
  components: {
    AppInputText,
    AppInputPassword,
  },
  props: {
    userEmail: {
      type: String,
      required: true,
      default: '',
    },
    password: {
      type: Function,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const password = ref('')
    const oldPassword = ref('')
    const { $t } = useI18nStore()

    const validPassword = reactive({
      capital: false,
      lower: false,
      minChar: false,
      symbol: false,
    })

    const isValid = computed(() => {
      let result =
        validPassword.capital &&
        validPassword.lower &&
        validPassword.minChar &&
        validPassword.symbol
      if (result) {
        if (!validation.oldPassword.$anyInvalid) {
          props.password(password.value)
        } else {
          props.password(null)
        }
        showHint.value = false
      }
      return result
    })

    let showHint = ref(false)

    watch(password, (pwd, prev) => {
      if (pwd !== prev && !showHint.value) {
        showHint.value = true
        props.password(null)
      }

      if (pwd.length >= 8) {
        validPassword.minChar = true
      } else {
        validPassword.minChar = false
      }

      if (/[a-z]/.test(pwd)) {
        validPassword.lower = true
      } else {
        validPassword.lower = false
      }

      if (/[A-Z]/.test(pwd)) {
        validPassword.capital = true
      } else {
        validPassword.capital = false
      }

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(pwd) || /[0-9]/.test(pwd)) {
        validPassword.symbol = true
      } else {
        validPassword.symbol = false
      }
    })

    const validation = useValidation({
      password: {
        $value: ref(password),
        required: {
          $validator: required.$validator,
          $message: $t('EditPasswordComponent.PasswordCannotBeEmptyMessage').value,
        },
      },
      oldPassword: {
        $value: ref(oldPassword),
        required: {
          $validator: required.$validator,
          $message: $t('EditPasswordComponent.PasswordCannotBeEmptyMessage').value,
        },
      },
    })

    function onInput(value) {
      this.$emit('input', value)
    }

    return { validation, $t, validPassword, isValid, showHint, onInput }
  },
}
</script>
<style scoped>
.hint {
  @apply text-xs;
  @apply leading-4 !important;
}
.password {
  @apply mb-1 !important;
}
.green {
  @apply text-green-text;
}
.red {
  @apply text-red-text;
}
</style>

<template>
  <!-- <div class="heading-container">
    <AppButton theme="secondary" @click="showLogoutDialog = true">{{
      $t('PageAccount.ButtonLogout').value
    }}</AppButton>
  </div> -->
  <LayoutDashboardCard class="settings-container">
    <AppSpinnerBig :loading="loading" />

    <AccountDetailsCard :user="user" />
    <LanguageCard />
    <NotificationCard :user="user" />

    <XeAppDescriptionList>
      <AppDescriptionListItem>
        <template #title>
          <p></p>
        </template>
        <template #content>
          {{ version }}
        </template>
      </AppDescriptionListItem>
    </XeAppDescriptionList>

    <!-- Account Logout Dialog -->
    <EditPersonalInfoModal
      v-if="editPersonalInfoModalOpen"
      v-model="editPersonalInfoModalOpen"
      :user="user"
      :onSave="onSaveSettings"
    />

    <AppDialog v-model="showLogoutDialog">
      <template #header>
        <AppDialogHeader>
          <h1>{{ $t('PageAccount.LogoutTitle').value }}</h1>
        </AppDialogHeader>
      </template>
      {{ $t('PageAccount.LogoutInfoText').value }}
      <template #footer>
        <AppDialogFooter>
          <AppButton theme="text" type="button" analytics-name="account-logout-yes" @click="logout">
            {{ $t('PageAccount.ButtonLogoutYes').value }}
          </AppButton>
          <AppButton
            type="button"
            analytics-name="account-logout-no"
            @click="showLogoutDialog = false"
          >
            {{ $t('PageAccount.ButtonLogoutNo').value }}
          </AppButton>
        </AppDialogFooter>
      </template>
    </AppDialog>

    <ModalRouterView />
  </LayoutDashboardCard>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import ModalRouterView from '@galileo/views/ModalRouterView'
import LayoutDashboardCard from '@galileo/components/LayoutDashboardCard/LayoutDashboardCard'

import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import AccountDetailsCard from '@galileo/components/Views/Account/SettingsCard/AccountDetailsCard'
import LanguageCard from '@galileo/components/Views/Account/SettingsCard/LanguageCard'
import NotificationCard from '@galileo/components/Views/Account/SettingsCard/NotificationCard'
import EditPersonalInfoModal from '@galileo/components/Views/Account/EditSettingsModal/EditPersonalInfoModal'

import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'

import {
  useMediaQuery,
  AppButton,
  AppDialog,
  AppDialogHeader,
  AppDialogFooter,
  AppSpinnerBig,
  useAlert,
  AppDescriptionListItem,
  AppInputToggle,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useEnvStore,
  useDeviceStore,
  useAnalyticsStore,
  useAuthStore,
} from '@galileo/stores'

export default {
  name: 'Account',
  components: {
    AppButton,
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppSpinnerBig,
    LayoutDashboardCard,
    ModalRouterView,
    EditPersonalInfoModal,
    AccountDetailsCard,
    LanguageCard,
    NotificationCard,
    XeAppDescriptionList,
    AppDescriptionListItem,
    AppInputToggle,
  },
  setup() {
    let editPersonalInfoModalOpen = ref(false)

    function triggerEditPeronalInfoSettings() {
      editPersonalInfoModalOpen.value = true
    }

    const { $t } = useI18nStore()
    const router = useRouter()
    const envStore = useEnvStore()
    const deviceStore = useDeviceStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const { isCorporateAccount } = authStore

    let version = envStore.getVariable('VUE_APP_XE_VERSION')

    const user = computed(() => authStore.user)

    const initials =
      user.value.customer.firstName.charAt(0).toUpperCase() +
      user.value.customer.lastName.charAt(0).toUpperCase()
    const showLogoutDialog = ref(false)
    const loading = ref(false)

    analyticsStore.track({
      event: SEGMENT_EVENTS.SETTINGS_ACCESSED,
    })

    const goToAccountPromotions = () => {
      router.push({ name: 'AccountPromotions' })
    }

    analyticsStore.track({
      event: SEGMENT_EVENTS.ACCOUNT_ACCESSED,
    })

    const logout = async () => {
      router.push({
        name: 'Login',
      })
    }

    const logoutForgetDevice = async () => {
      deviceStore.forgetDevice()
      await logout()
    }

    async function onSaveSettings() {
      loading.value = true
      const { add } = useAlert()
      add(`${$t('PageAccount.AccountDetailsUpdatedText').value}`)
      //fetch the user again
      await authStore.getUserAction(user.value.customer.id)
      loading.value = false
    }

    return {
      mq: useMediaQuery(),
      user,
      isCorporateAccount,
      loading,
      initials,
      showLogoutDialog,
      editPersonalInfoModalOpen,
      triggerEditPeronalInfoSettings,
      goToAccountPromotions,
      logoutForgetDevice,
      onSaveSettings,
      logout,
      $t,
      version,
    }
  },
}
</script>

<style scoped>
.heading-container {
  @apply px-12 flex justify-between pt-8;
}

.heading-container > button {
  @apply w-auto;
}

::v-deep .heading-container > .button--secondary {
  @apply px-4 py-2.5;
  color: #333333;
  background-color: #f3f4f6;
}

::v-deep .footer > .button--secondary {
  @apply w-auto px-2.5 py-4;
  color: #cd3d64;
  background-color: #ffe1e6;
}

.heading-container > h2 {
  @apply text-xl font-medium;
  color: #333333;
}

.verified {
  color: #08815c;
}

.not-verified {
  color: var(--theme-color-primary, theme('colors.blue.default'));
}

.footer {
  @apply flex justify-center pb-12;
}
</style>

<template>
  <div>
    <h2 class="mb-8 text-2xl leading-8">
      {{ $t('PageAccount.SettingsText').value }}
    </h2>

    <AppAlert v-if="isCorporateAccount" class="mb-8" theme="yellow">
      <p class="alert-text">{{ $t('PageAccount.VisitHelpCenterAlertText').value }}</p>
      <AppAlertAction @click="onHelpCenterClick">
        {{ $t('PageAccount.VisitHelpCenterAlertAction').value }}
      </AppAlertAction>
    </AppAlert>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'

import { AppAlert, AppAlertAction } from '@oen.web.vue2/ui'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import { useAuthStore, useI18nStore, useAppStore } from '@galileo/stores'

export default defineComponent({
  components: {
    AppAlert,
    AppAlertAction,
  },
  setup(props) {
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const onHelpCenterClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.HOW_DO_I_UPDATE_MY_ACCOUNT_DETAILS,
        article: HelpDesk[HelpDeskProperties.HOW_DO_I_UPDATE_MY_ACCOUNT_DETAILS],
      })
    }

    const { isCorporateAccount } = storeToRefs(authStore)

    return { $t, isCorporateAccount, onHelpCenterClick }
  },
})
</script>
<style>
.alert-text {
  color: rgba(200, 144, 38);
}
</style>

<template>
  <XeAppDescriptionList borderTop>
    <AppDescriptionListItem>
      <template #title>
        <p v-if="isCorporateAccount">
          {{ $t('PageAccount.PersonalInfoSectionCompanyAddressLabel').value }}
        </p>
        <p v-else>
          {{ $t('PageAccount.PersonalInfoSectionAddressLabel').value }}
        </p>
      </template>
      <template #content>
        <AppButton v-if="!isCorporateAccount" theme="icon" @click="editAddress">
          <AppIcon name=" ">
            <IconEdit />
          </AppIcon>
        </AppButton>
      </template>
      <template #action> </template>
    </AppDescriptionListItem>
    <p class="description-list-item-definition">
      {{ formattedAddress.value }}
    </p>
  </XeAppDescriptionList>
</template>

<script>
import { IconEdit } from '@oen.web.vue2/icons'

import { defineComponent, ref, computed } from '@vue/composition-api'

import { storeToRefs } from 'pinia'

import Address from '@galileo/models/Address/address'
import { useAddress } from '@galileo/composables/useAddress'

import { AppDescriptionListItem, AppButton, AppIcon } from '@oen.web.vue2/ui'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'



import { useI18nStore, useAuthStore } from '@galileo/stores'

export default defineComponent({
  emits: ['editAddress'],
  components: {
    AppDescriptionListItem,
    XeAppDescriptionList,
    AppButton,
    AppIcon,
    IconEdit,
  },
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()

    const { isCorporateAccount } = storeToRefs(authStore)

    const editAddress = () => {
      emit('editAddress')
    }

    let userAddress = new Address(
      props.user.address,
      props.user.extraFields.place,
      props.user.extraFields.state,
      props.user.extraFields.postalCode,
      props.user.country
    )

    const formattedAddress = computed(() => {
      userAddress = new Address(
        props.user.address,
        props.user.extraFields.place,
        props.user.extraFields.state,
        props.user.extraFields.postalCode,
        props.user.country
      )
      const { formattedAddress } = useAddress(userAddress)
      return formattedAddress
    })

    return { isCorporateAccount, $t, formattedAddress, editAddress }
  },
})
</script>

<style>
.description-list-item-definition {
  @apply text-sm leading-5 text-gray-text;
}
</style>

<template>
  <div>
    <SettingsCard :title="$t('PageAccount.AccountSectionText').value">
      <template #pageTitle>
        <AccountDetailsHeader />
      </template>
      <AppSpinnerBig :loading="loading" />

      <XeAppDescriptionList borderTop>
        <AppDescriptionListItem>
          <template #title> {{ $t('PageAccount.AccountSectionNumberLabel').value }}</template>
          <template #content>
            <p>{{ user.customer.clientNumber }}</p>
          </template>
        </AppDescriptionListItem>
      </XeAppDescriptionList>

      <AccountDetailsNameListItem :user="user" @editName="editName" />
      <AccountDetailsEditEmaiListItem :user="user" @edit="showEditEmailAlert" />

      <XeAppDescriptionList borderTop>
        <AppDescriptionListItem>
          <template #title>{{ $t('PageAccount.AccountSectionPasswordLabel').value }}</template>
          <template #content>
            <p>**********</p>
            <AppButton theme="icon" @click="editPassword">
              <AppIcon name=" ">
                <IconEdit />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </XeAppDescriptionList>

      <XeAppDescriptionList borderTop>
        <AppDescriptionListItem>
          <template #title>{{ $t('PageAccount.PersonalInfoSectionPhoneLabel').value }}</template>
          <template #content>
            <p>{{ user.mobilePhone.phoneNumberFormatted }}</p>
            <AppButton theme="icon" @click="editPhone">
              <AppIcon name=" ">
                <IconEdit />
              </AppIcon>
            </AppButton>
          </template>
        </AppDescriptionListItem>
      </XeAppDescriptionList>

      <AccountDetailsAddressListItem :user="user" @editAddress="editAddress" />
      <EditAddressModal v-model="showEditAddressModal" :user="user" :on-save="onSaveAddress" />

      <EditPasswordModal v-model="showEditPasswordModal" :on-save="onSaveSettings" />

      <EditNameModal
        v-model="showEditNameModal"
        v-if="showEditNameModal"
        :user="user"
        :on-save="onSaveSettings"
      />

      <EditPhoneModal
        v-if="showEditPhoneModal"
        v-model="showEditPhoneModal"
        :user="user"
        :on-save="onSaveSettings"
      />

      <EditEmailModal v-model="showEditEmailModal" :user="user" :on-save="onSaveSettings" />

      <InformationAppModal :value="showEditEmailAlertModal" icon="mailBox" class="email-edit-alert">
        <h1 class="title">{{ $t('ChangeEmailModal.Title').value }}</h1>
        <p class="textInfo" v-html="$t('ChangeEmailModal.Text').value" />

        <template #footer>
          <AppButton @click="showEmailEdit">
            {{ $t('ComponentCardCVVModal.ButtonGotIt').value }}
          </AppButton>
          <AppButton theme="secondary" @click="showEditEmailAlertModal = false">
            {{ $t('PageAccount.ButtonLogoutNo').value }}
          </AppButton>
        </template>
      </InformationAppModal>
    </SettingsCard>
    <VerifyNotSuppliedModal />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount } from '@vue/composition-api'

import SettingsCard from '@galileo/components/Views/Account/SettingsCard/SettingsCard'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'
import { IconEdit } from '@oen.web.vue2/icons'
import EditEmailModal from '@galileo/components/Views/Account/EditSettingsModal/EditEmailModal'

import Address from '@galileo/models/Address/address'
import { useAddress } from '@galileo/composables/useAddress'

import EditAddressModal from '@galileo/components/Views/Account/EditSettingsModal/EditAddressModal/EditAddressModal'
import EditPasswordModal from '@galileo/components/Views/Account/EditSettingsModal/EditPasswordModal'
import EditNameModal from '@galileo/components/Views/Account/EditSettingsModal/EditNameModal'
import EditPhoneModal from '@galileo/components/Views/Account/EditSettingsModal/EditPhoneModal'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import AccountDetailsHeader from '@galileo/components/Views/Account/SettingsCard/AccountDetailsMolecules/AccountDetailsHeader.vue'
import AccountDetailsNameListItem from '@galileo/components/Views/Account/SettingsCard/AccountDetailsMolecules/AccountDetailsNameListItem.vue'
import AccountDetailsAddressListItem from '@galileo/components/Views/Account/SettingsCard/AccountDetailsMolecules/AccountDetailsAddressListItem.vue'
import VerifyNotSuppliedModal from '@galileo/components/Views/SendMoney/VerifyNotSuppliedModal'

import {
  useI18nStore,
  useResourcesStore,
  useEnvStore,
  useAnalyticsStore,
  useAuthStore,
  useProfileStore,
  usePromotionStore,
} from '@galileo/stores'

import {
  AppDescriptionListItem,
  AppInputToggle,
  AppButton,
  AppIcon,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'
import AccountDetailsEditEmaiListItem from './AccountDetailsMolecules/AccountDetailsEditEmaiListItem.vue'

export default defineComponent({
  components: {
    SettingsCard,
    AppDescriptionListItem,
    XeAppDescriptionList,
    AppInputToggle,
    AppButton,
    AppSpinnerBig,
    AppIcon,
    IconEdit,
    EditAddressModal,
    EditPasswordModal,
    EditNameModal,
    EditPhoneModal,
    EditEmailModal,
    InformationAppModal,
    AccountDetailsHeader,
    AccountDetailsNameListItem,
    AccountDetailsAddressListItem,
    VerifyNotSuppliedModal,
    AccountDetailsEditEmaiListItem,
  },
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const analyticsStore = useAnalyticsStore()
    const promoStore = usePromotionStore()
    const loading = ref(false)

    const profileStore = useProfileStore()
    const showEditAddressModal = ref(false)
    const showEditPasswordModal = ref(false)
    const showEditNameModal = ref(false)
    const showEditPhoneModal = ref(false)

    const showEditEmailModal = ref(false)
    const showEmailEdit = () => {
      showEditEmailModal.value = true
      showEditEmailAlertModal.value = false
    }

    const showEditEmailAlertModal = ref(false)
    const showEditEmailAlert = () => {
      showEditEmailAlertModal.value = true
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_EMAIL_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
        },
      })
    }
    let userAddress = new Address(
      props.user.address,
      props.user.extraFields.place,
      props.user.extraFields.state,
      props.user.extraFields.postalCode,
      props.user.country
    )

    const formattedAddress = computed(() => {
      userAddress = new Address(
        props.user.address,
        props.user.extraFields.place,
        props.user.extraFields.state,
        props.user.extraFields.postalCode,
        props.user.country
      )
      const { formattedAddress } = useAddress(userAddress)
      return formattedAddress
    })

    async function onSaveSettings() {
      loading.value = true
      // Fetch the user again
      await authStore.getUserAction(props.user.customer.id)
      loading.value = false
    }

    async function onSaveAddress() {
      loading.value = true
      analyticsStore.track({
        event: SEGMENT_EVENTS.ADDRESS_UPDATED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
        },
      })
      // Fetch the user again
      await authStore.getUserAction(props.user.customer.id)
      // Fetch the system fields again to update T&Cs URLs
      const resourcesStore = useResourcesStore()
      await resourcesStore.getSystemFields()
      //get new values for promotion
      await promoStore.getAvailablePromotionsPerUserCountry()
      await authStore.refresh(authStore.token?.refreshToken)
      loading.value = false
    }

    const editPassword = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PASSWORD_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
        },
      })
      showEditPasswordModal.value = true
    }

    const editName = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_NAME_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
        },
      })
      showEditNameModal.value = true
    }

    const editAddress = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PERSONAL_ADDRESS_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
        },
      })
      showEditAddressModal.value = true
    }

    const editPhone = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PHONE_NUMBER_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SETTINGS,
        },
      })
      showEditPhoneModal.value = true
    }

    onBeforeMount(async () => {
      await profileStore.getMarketingConsent()
    })

    return {
      loading,
      formattedAddress,
      $t,
      showEditAddressModal,
      showEditPasswordModal,
      showEditNameModal,
      showEditPhoneModal,
      onSaveSettings,
      onSaveAddress,
      editPassword,
      editName,
      editAddress,
      editPhone,
      showEditEmailModal,
      showEmailEdit,
      showEditEmailAlertModal,
      showEditEmailAlert,
    }
  },
})
</script>

<style scoped>
::v-deep .description-list-item-definition {
  @apply flex flex-row;

  * {
    @apply flex-auto;
  }

  .button {
    @apply w-auto;
  }

  p {
    @apply pl-2;
    overflow-wrap: anywhere;
  }
}

.textInfo {
  @apply pb-4;
}
</style>
